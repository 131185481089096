.app__calendaritem {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__calendaritem-head {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__calendaritem-sub {
    width: 100%;
    margin-top: 0.2rem;
}

.app__calendaritem-name {
    flex: 1;
}

.p__opensans-date{
    font-family: var(--font-alt);
    color: var(--color-golden);
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 18px;
}

.p__opensans-time {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 28px;
    font-size: 18px;
}

.app__calendaritem-dash {
    width: 100px;
    height: 1px;
    background: var(--color-golden);
    margin: 0 0.5rem;
}

.app__calendaritem-location {
    display: flex;
    justify-content: flex;
    align-items: flex;
}