.app__gallery {
    flex-direction: column;
    background: var(--color-black);
    width: 100%;
    margin-bottom: 12rem;
}

.app__gallery-title{
    margin-top: 5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.app__gallery-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-right: 2rem;
}

.app__gallery-content button {
    margin-top: 1rem;
}

.app__gallery-images {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
    z-index:0;
}

.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
    z-index: 0;
}

.app__gallery-images_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-images_card {
    position: relative;
    min-width: 301px;
    height: 447px;
    margin-right: 2rem;
    z-index: 0;
}

.gallery__image-icon {
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;
}

.app__gallery-images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .5s ease;
    z-index: 0;
}

.app__gallery-images_card .a {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .5s ease;
    z-index: 0;
}

.app__wrapper_video_gallery {
    margin-bottom: 1rem;
}

.youtube_embed {
    width: 560px;
    text-align: center;
}

.youtube_embed .embededvid {
    text-align: center;
    justify-content: center;
    outline-style: solid;
    outline-color: var(--color-golden);
    width: 500px;
}


.app__gallery-images_card:hover img {
    opacity: .35;
}

.app__gallery-images_card:hover .gallery__image-icon {
    opacity: 1;
}

.app__gallery-images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1rem;
    position: absolute;
    bottom: 10%;
}

.gallery__arrow-icon {
    color: var(--color-golden);
    font-size: 3rem;
    cursor: pointer;
    background: var(--color-black);
    border-radius: 5px;
}

.gallery__arrow-icon:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__gallery-content button {
        margin-top: 2rem;
    }

    .app__gallery-content {
        min-width: 800px;
    }

    .app__gallery-images_card {
        min-width: 400px;
        height: 547px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
        padding: 0 1rem 4rem 1rem;
    }

    .app__gallery-images {
        max-width: 100%;
        margin: 1rem 0;
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 0 1rem 3rem 1rem;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 0 1rem 3rem 1rem;
    }  
    
    .app__gallery-content {
        min-width: 100%;
    }

    .app__gallery-images_card {
        min-width: 240px;
        height: 320px;
    }
}

.app__articles {
    justify-content: space-between;
    background: var(--color-black);
}

.app__articles-title {
    margin-bottom: 2rem;
    text-align: center;
}

.table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 10px;
}

.app__articles-feature {
    width: 100%;
    margin: 2rem 0;
    background-color: rgba(42, 39, 39, 0.449);
    border-radius: 20px;
    border-spacing: 10px;
    padding: 1.5rem;
    border: 2px;
    outline-style: solid;
    outline-color: var(--color-golden);
    display: table;
    vertical-align: middle;
}


.tr {
    display: table-row;
    vertical-align: middle;
    border-color: inherit;
}

.app__articles-article {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    font-size: 120%;
}

.app__articles-author {
    display: block;
    margin-top: 1rem;
}

.app__article-authname {
    font-size: 25px;
    color: var(--color-golden);
}

.app__article-theatre {
    color: var(--color-white);
    display: block;
    font-size: 100%;
    vertical-align: inherit;
}

.app__article-theatname {
}

.app__articles-dates {
    color: var(--color-white);
    padding: 0rem;
    margin: 0;
    font-size: 115%;
}

.app__articles-link-container {
    text-align: right;
    display: table-cell;
    vertical-align: inherit;
}

.app__articles-link {
    white-space: nowrap;
    background-color: rgb(214, 188, 40);
    color: black;
    padding: 1rem 1rem;
    border-radius: 15px;
    border:0px;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 100%;
    letter-spacing: 0.04em;
    transition: background-color 0.5s;
    display: inline-block;
    text-align: center;
    width: 150px;
}

.app__articles-link:hover {
    color:white;
    background-color: rgb(138, 121, 24);
}

.app__articles-feature-left {
    flex-direction: column;
}

.app__articles-feature_img img {
    width: 100%;
    height: auto;
}

.app__articles_feature_items {
    display: flex;
    flex-direction: column;
}

.app__articles_feature_items .order-links_article{
    background-color: rgb(214, 188, 40);
    color: black;
    padding: 1rem 1rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
    border-radius: 15px;
    border:0px;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.04em;
    transition: background-color 0.5s;
}

.headtext__cormorant-article-header {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 30px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    margin: 5%;
}

#order-linkscontainer:hover .order-links_article{
    color:white;
    background-color: rgb(138, 121, 24);
}

@media screen and (min-width: 2000px) {
    .app__articles-feature_img {
        width: 650px;
    }

    .app__articles-feature_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
}

@media screen and (max-width: 1000px) {
    .app__articles-article, .app__articles-link-container, .app__articles-dates {
        display: block;
        text-align: center;
    }

    .app__articles-link-container, .app__articles-dates {
        margin-top: 2rem;
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 650px) {
    .app__articles-feature_img {
        width: 100%;
    }

    .app__articles-feature_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}