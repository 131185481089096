.app__shows {
    justify-content: space-between;
    background: var(--color-darkblack);
    margin-top: 4rem;
    padding: 2rem;
}

.app__shows-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 10px;
}

.app__shows-calendar {
    width: 100%;
    margin: 2rem 0;
    background-color: rgba(42, 39, 39, 0.449);
    border-radius: 20px;
    border-spacing: 10px;
    padding: 1.5rem;
    border: 2px;
    outline-style: solid;
    outline-color: var(--color-crimson);
    display: table;
    vertical-align: middle;
}


.tr {
    display: table-row;
    vertical-align: middle;
    border-color: inherit;
}

.app__shows-event {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    font-size: 120%;
}

.app__shows-location {
    display: block;
    vertical-align: inherit;
}

.app__show-locname {
    font-size: 25px;
    color: var(--color-golden);
}

.app__show-theatre {
    color: var(--color-white);
    display: block;
    font-size: 100%;
    vertical-align: inherit;
}

.app__show-theatname {
}

.app__show-theatname-sub {
    color: var(--color-golden);
    font-style: italic;
}

.app__shows-dates {
    color: var(--color-white);
    padding: 0rem;
    margin: 0;
    font-size: 115%;
}

.app__shows-ticket-container {
    text-align: right;
    display: table-cell;
    vertical-align: inherit;
}

.app__shows-ticket {
    white-space: nowrap;
    background-color: rgb(214, 188, 40);
    color: black;
    padding: 1rem 1rem;
    border-radius: 15px;
    border:0px;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 100%;
    letter-spacing: 0.04em;
    transition: background-color 0.5s;
    display: inline-block;
    text-align: center;
    width: 175px;
}

.app__shows-ticket:hover {
    color:white;
    background-color: rgb(138, 121, 24);
}


.app__shows-calendar .app__shows-calendar-perflocation{
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 20px;
    flex-direction: column;
    height: 30px;
    width: auto;
}

.app__shows-calendar-left {
    flex-direction: column;
}

.app__shows-calendar_img img {
    width: 100%;
    height: auto;
}

.app__shows_calendar_items {
    display: flex;
    flex-direction: column;
}

.app__shows_calendar_items .order-tickets_show{
    background-color: rgb(214, 188, 40);
    color: black;
    padding: 1rem 1rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
    border-radius: 15px;
    border:0px;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.04em;
    transition: background-color 0.5s;
}

.headtext__cormorant-show-header {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 30px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    margin: 5%;
}

#order-ticketscontainer:hover .order-tickets_show{
    color:white;
    background-color: rgb(138, 121, 24);
}

@media screen and (min-width: 2000px) {
    .app__shows-calendar_img {
        width: 650px;
    }

    .app__shows-calendar_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
}

@media screen and (max-width: 1000px) {
    .app__shows-event, .app__shows-ticket-container, .app__shows-dates {
        display: block;
        text-align: center;
    }

    .app__shows-ticket-container, .app__shows-dates {
        margin-top: 2rem;
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 650px) {
    .app__shows-calendar_img {
        width: 100%;
    }

    .app__shows-calendar_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}