.app__header {
    background-color: var(--color-black);
    margin-bottom: 4rem;
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-red);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 117px;
    font-size: 90px;
}

.navbar_spacer{
    margin-bottom:3rem;
}

.SubHeading {
    color: var(--color-white);
}

.app__header-img img {
    width: 80%;
}

.youtube_embed {
    width: 560px;
    text-align: center;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1400px) {
    .app__wrapper_img .homepage_hero{
        width: 750px;
        height: auto;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 80px;
    }
    .app__header {
        margin-bottom: 11rem;
    }

    .app__wrapper_img .homepage_hero{
        width: 600px;
        height: auto;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 60px;
        line-height: 87px;
    }

    .app__wrapper_img .homepage_hero{
        width: 400px;
        height: auto;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 45px;
        line-height: 70px;
    }

    .app__wrapper_img .homepage_hero{
        width: 350px;
        height: auto;
    }
}