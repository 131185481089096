.mailinglist-button{
    background-color: rgb(214, 188, 40);
    color: black;
    border-radius: 15px;
    border:0px;
    padding: 1rem 1rem;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.04em;
}

#mailinglist{
    position: relative;
    text-align: center;
    margin-bottom: 2rem;
}

#mailinglist:hover .mailinglist-button{
    color:white;
    background-color: rgb(138, 121, 24);
}

@media screen and (max-width: 650px) {

    .mailinglist-button {
        font-size: 15px;
    }
}