.wpas_container{
    margin-top: 2rem;
    margin-bottom: 16rem;
    align-items: center;
    text-align: center;
    align-content: center;
}

.app__wpas-title{
    margin-top: 5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.wpas_video_container{
    text-align: center;
    align-items: center;
    align-content: center;
}

.app__wpas-quotes{ 
    text-align: center;
    align-items: center;
    align-content: center;
    height: auto;
    padding-left: 15%;
    padding-right: 15%;
}

.wpas_youtube {
    width: 1200px;
    height: 800px;
}

@media screen and (min-width: 2000px) {
    .wpas_youtube {
        width: 1200px;
        height: 800px;
    }
}

@media screen and (max-width: 1150px) {
    .wpas_youtube {
        width: 800px;
        height: 500px;
    }
}
@media screen and (max-width: 900px) {
    .wpas_youtube {
        width: 600px;
        height: 400px;
    }
}

@media screen and (max-width: 650px) {
    .wpas_youtube {
        width: 400px;
        height: 300px;
    }
}

@media screen and (max-width: 450px) {
    .wpas_youtube {
        width: 300px;
        height: 250px;
    }
}