.section__padding {
    padding: 4rem 6rem;
}

.section__padding_links {
    padding: 2rem 20rem;
}

.page {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 100%;
    background-color: var(--color-darkblack);
}

html, body {
    min-height:100%;
}

/**************************/
/* CSS Animations Classes */
/**************************/

/* 
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
    opacity: 0;
    transform: translate(0, 25px);
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
    opacity: 1;
    transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translate(0, 30px);
    transition: opacity 250ms ease-out, transform 300ms ease;
}

.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex__center_calendar{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
}

.app__bg {
    background: var(--color-darkblack);
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
}

.app__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50vh;
}

.app__wrapper_info {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.app__wrapper_img {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.app__wrapper_video {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.app__wrapper_video_gallery {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0rem;
    margin-bottom: 2rem;
}

.app__wrapper_img-reverse {
    justify-content: flex-start;
    margin-right: 2rem;
}

.app__wrapper_img img {
    width: 80%;
}

.app__wrapper_video .LiteYouTubeEmbed {
    width: 560px;
    height: 350px;
}

.app__wrapper_video_gallery .youtube_embed {
    width: 560px;
    height: 350px;
}

.custom__button {
    background-color: var(--color-crimson);
    color: var(--color-white);
    font-family: var(--font-base);
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}

.icons-bar {
    text-align: center;
    align-items: center;
    display: flex;
}

#facebook-button {
    position: fixed;
    bottom: 6rem;
    right: 10px;
    width: 50px;
}

.fb-connect{
    color: black;
    padding: 1rem 1rem;
    margin-right: 1rem;
    border-radius: 15px;
    border:0px;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.04em;
    background-color:transparent;
}


.p__cormorant {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-feature-settings: 'tnum' on, 'lnum' on;
    line-height: 29.9px;
    font-size: 23px;
}

.p__opensans {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
}

.p__opensans_profile{
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding-bottom: 20px;
}

.p__opensans_about{
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding-bottom: 20px;
}

.p__opensans_about_threecord {
    font-family: var(--font-alt);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 20px;
    padding-bottom: 20px;
}

.p__opensans_about_threecord_bold {
    font-family: var(--font-alt);
    color: var(--color-golden);
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 25px;
    padding-bottom: 20px;
}

.p__opensans_collaborators{
    font-family: var(--font-alt);
    color: var(--color-golden);
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 18px;
    padding-bottom: 20px;
}

.p__opensans_support{
    font-family: var(--font-alt);
    color: var(--color-golden);
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 18px;
    padding-bottom: 20px;

}

.p__opensans_contact {
    font-family: var(--font-alt);
    color: var(--color-golden);
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 18px;
    padding-bottom: 20px;
}

.headtext__cormorant {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 64px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
}

.yt-lite {
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
}

/* gradient */
.yt-lite::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
    background-position: top;
    background-repeat: repeat-x;
    height: 60px;
    padding-bottom: 50px;
    width: 100%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
    content: "";
    display: block;
    padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
    width: 70px;
    height: 46px;
    background-color: #212121;
    z-index: 1;
    opacity: 0.8;
    border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
    background-color: #f00;
    opacity: 1;
}
/* play button triangle */
.yt-lite > .lty-playbtn:before {
    content: '';
    border-style: solid;
    border-width: 11px 0 11px 19px;
    border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
    cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
    opacity: 0;
    pointer-events: none;
}

@media screen and (min-width: 2000px) {
    .custom__button,
    .p__cormorant {
        font-size: 37px;
        line-height: 67px;
    }

    .p__opensans {
        font-size: 30px;
        line-height: 50px;
    }

    .headtext__cormorant {
        font-size: 150px;
        line-height: 210px;
    }

    .spoon__img {
        width: 80px;
    }
}

@media screen and (max-width: 1600px) {
    .section__padding_links {
        padding: 2rem 10rem;
    }
}

@media screen and (max-width: 1400px) {
    .app__wrapper {
        flex-direction: column;
    }

    .app__wrapper_img {
        margin: 5rem 0 0 0;
    }
    .app__wrapper_video {
        margin: 10rem 0 0 0;
    }

    .app__wrapper_img-reverse {
        margin: 0 0 5rem 0;
    }

    .app__wrapper_img img {
        width: 100%;
    }

    .app__wrapper_video iframe {
        width: 100%;
    }

    .app__wrapper_video_gallery .youtube_embed {
        width: 450px;
        height: 100%;
    }
    
}

@media screen and (max-width: 850px) {
    .section__padding {
        padding: 4rem;
    }

    .section__padding_links {
        padding: 2rem 7rem;
    }
}

@media screen and (max-width: 650px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__padding_links {
        padding: 1rem 2rem;
    }
    
    .p__cormorant {
        font-size: 21px;
    }

    .p__opensans {
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .p__opensans {
        font-size: 12px;
    }

    .p__cormorant {
        font-size: 19px;
    }

    .headtext__cormorant {
        font-size: 45px;
        line-height: 70px;
    }
}