.reviewblurbs_container{
    align-items: center;
    text-align: center;
    align-content: center;
    background-color: var(--color-darkblack) ;
}

.app__reviewblurbs-quotes{ 
    text-align: center;
    align-items: center;
    align-content: center;
    height: auto;
    padding-left: 15%;
    padding-right: 15%;
    background-color: var(--color-darkblack);
}


@media screen and (min-width: 2000px) {

}

@media screen and (max-width: 1150px) {

}
@media screen and (max-width: 900px) {

}

@media screen and (max-width: 650px) {

}

@media screen and (max-width: 450px) {

}