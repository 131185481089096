@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
    --font-base: 'Noto Sans', serif;
    --font-alt: 'Open Sans', sans-serif;

    --color-darkblack: #040404;
    --color-red: #b51a00;
    --color-golden: #DCCA87;
    --color-black: #0C0C0C;
    --color-gray: #545454;
    --color-crimson: #96300e;
    --color-grey: #AAAAAA;
    --color-white: #FFFFFF;
    --color-backgrey: #292726;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

a {
    color: unset;
    text-decoration: none;
}

.slide-bottom {
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}