.app__creators {
    flex-direction: column;
    background: var(--color-darkblack);
    color: var(--color-darkblack);
    margin-bottom: 5rem;
}

.app__creators-title {
    margin-bottom: 0rem;
    padding-top: 5rem;
    background: var(--color-darkblack);
    text-align: center;
}


.app__creators-profile_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    background: var(--color-darkblack);
}

.app__creators_profile {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__creators-profile_img {
    width: 410px;
}

.app__creators-profile_img img {
    width: 100%;
    height: auto;

}

.app__profile-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0rem;
}

.app__wrapper_info .headtext__cormorant {
    margin-bottom: 1rem;    
    font-size: 40px;
}



@media screen and (min-width: 2000px) {
    .app__shows-calendar_img {
        width: 650px;
    }

    .app__shows-calendar_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
    .app__shows-calendar {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app__shows-calendar_img {
        width: 100%;
    }

    .app__shows-calendar_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}