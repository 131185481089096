.app__podcasts {
    background: var(--color-darkblack);
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    font-size: 62.5%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0rem;
    width: 100%;
    text-align: center;
}

.app__podcasts-title {
    margin-bottom: 2rem;
    text-align: center;
}

.table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 10px;
}

.app__podcasts-feature {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: rgba(42, 39, 39, 0.449);
    border-radius: 20px;
    border-spacing: 10px;
    padding: 1.5rem;
    border: 2px;
    outline-style: solid;
    outline-color: var(--color-golden);
    display: table;
    vertical-align: middle;
    text-align: center;
    justify-content: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    justify-content: space-between;
    
}

.app__podcasts-image .img {
    height: 30px;
}


.tr {
    display: table-row;
    vertical-align: middle;
    border-color: inherit;
}

.app__podcasts-podcast {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    font-size: 120%;
    display: block;
    text-align: center;
}

.app__podcasts-image {
    height: 20px;
    display: block;
    margin-top: 1rem;
}

.app_podcasts-feature-container {
    width: 100%;
    
}

.app__podcasts-feature_imagecontainer {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    justify-content: space-between;
    align-items: center;
    display: block;
}
.app__podcasts-feature_image {
    min-width: 250px;
    max-width: 40%;
    margin-top: 1rem;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    align-items: center;
    
}

.app__podcasts-author {
    display: block;
    margin-top: 1rem;
}

.app__podcast-authname {
    font-size: 25px;
    color: var(--color-golden);
}

.app__podcasts-link-container {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-box-align: center;
    justify-content: space-between;
    align-items: center;
    display: block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 0rem;
}

.app__podcasts-link {
    white-space: nowrap;
    background-color: rgb(214, 188, 40);
    color: black;
    padding: 1rem 1rem;
    border-radius: 15px;
    border:0px;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 100%;
    letter-spacing: 0.04em;
    transition: background-color 0.5s;
    width: 150px;
}

.app__podcasts-link:hover {
    color:white;
    background-color: rgb(138, 121, 24);
}

.app__podcasts-feature-left {
    flex-direction: column;
}

.app__podcasts-dates {
    margin-top: 2rem;
    margin-bottom: 0rem;
    display: block;
    text-align: center;
}

.app__podcasts-feature_image {

}

.app__podcasts-feature_image img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.app__podcasts_feature_items {
    display: flex;
    flex-direction: column;
}

.app__podcasts_feature_items .order-links_podcast{
    background-color: rgb(214, 188, 40);
    color: black;
    padding: 1rem 1rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
    border-radius: 15px;
    border:0px;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.04em;
    transition: background-color 0.5s;
}

.collapsible .headtext__cormorant-podcast-title {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 60px;
    line-height: 43.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
}

#order-linkscontainer:hover .order-links_podcast{
    color:white;
    background-color: rgb(138, 121, 24);
}

.collapsible {
    width: 100vw;
    padding: 0;
    margin: 0;
    text-align: center;
    align-items: center;
    align-content: center;
    outline-color: var(--color-crimson);
    border-radius: 20px;
    outline-style: solid;
    background-color: (42, 39, 39, 0.449);
}

.collapsible .content {
    font-size: 30px;
    background-color: var(--color-golden);
    text-align: center;
    min-width: 200px;
}


.collapsible .header {
    font-size: 20px;
    background-color: var(--color-darkblack);
    color: var(--color-golden);
    text-align: center;
    border-radius: 15px;
    padding: 0.3rem;
    border:20px;
    font-family: var(--font-base);
    font-weight: 600;
    transition: background-color 0.5s;
    display:flex;
    align-items:center;
    justify-content: center;
    text-align: center;
}

.header .headerContent {
    display:flex;
    align-items:center;
    border-radius: 15px;
    border: 30px;
    padding: 1rem;
    background-color: var(--color-golden);
    font-size: 30px;
    justify-content: center;
    width: 100%;
}

.header:hover > .headerContent {
    background-color: var(--color-crimson);
}



.grid {
    display: grid;
    width: 100%;
    padding: 1rem;
    grid-gap: 5rem;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    justify-items: center;
}

.grid__item {
    width: 25rem;
    height: 35rem;
    background-color: white;
    border-radius: 0.4rem;
    padding: 1.5rem;
    box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.2s;
}
.grid__item:hover {
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
}

.card__img {
    display: block;
    width: 100%;
    height: 10rem;
    object-fit: cover;
}
.card__content {
    padding: 2.5rem 2.5rem;
}
.card__header {
    font-size:25px;
    font-weight: 600;
    color: var(--color-crimson);
    height: 5rem;
}
.card__text {
    font-size: 20px;
    letter-spacing: 0.1rem;
    line-height: 1.7;
    color: #3d3d3d;
    height: 10rem;
}
.card__btn {
    display: block;
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    color: black;
    background-color: var(--color-golden);
    border: none;
    border-radius: 0.4rem;
    transition: 0.2s;
    cursor: pointer;
}
.card__btn span {
    margin-left: 1rem;
    transition: 0.2s;
}
.card__btn:hover, .card__btn:active {
    background-color: rgb(138, 121, 24);

}
.card__btn:hover span, .card__btn:active span {
    margin-left: 1.5rem;
}

@media screen and (min-width: 2000px) {
    /*.app__podcasts-feature_img {
        width: 650px;
    }

    .app__podcasts-feature_img img {
        height: 920px;
    }*/
}

@media screen and (max-width: 1150px) {
    .collapsible .headtext__cormorant-podcast-title {
        font-size: 45px;
    }
}

@media screen and (max-width: 1px) {
    .app__podcasts-podcast, .app__podcasts-link-container, .app__podcasts-dates, .app__podcasts-feature_image {
        display: block;
        text-align: center;
    }

    .app__podcasts-link-container, .app__podcasts-dates {
        margin-top: 2rem;
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 650px) {
    /*.app__podcasts-feature_img {
        height: 50px;
    }*/

    .grid {
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    }

    .grid__item {
        width: 20rem;
        height: 40rem;
    }

    .card__text {
        height: 15rem;
    }

    .card__header {
        height: 5rem;
    }

    .app__podcasts-feature_heading {
        font-size: 35px;
        line-height: 48.5px;
    }

    .collapsible .headtext__cormorant-podcast-title {
        font-size: 30px;
    }
}