.QuotesCarouselContainer{
    font-family: sans-serif;
    font-weight: 200;
    font-size: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-family: var(--font-base);
    color: var(--color-white);
    letter-spacing: 0.04em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    min-height: 50vh;
}

.QuotesCarousel {
    font-family: sans-serif;
    font-weight: 200;
    font-size: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-family: var(--font-base);
    color: var(--color-white);
    letter-spacing: 0.04em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    min-height: 50vh;
}

@media screen and (max-width: 1000px) {
    .QuotesCarousel, .QuotesCarouselContainer {
        min-height: 70vh;
    }
}

@media screen and (max-width: 650px) {
    .QuotesCarousel, .QuotesCarouselContainer {
        min-height: 100vh;
    }
}