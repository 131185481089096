.app__supportus {
    flex-direction: column;
}

.app__supportus-container {
    flex-direction: column;
    padding-bottom: 2rem;
    padding-top: 4rem;
}

.app__supportus-title {
    text-align: center;
}

.app__wrapper_support {
    height: auto;
    display: flex;
    flex-direction: column;
    outline-color: transparent;
}

.app__supportus-body {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0rem;
}

.donate_container {
    top: 50%;
    left: 50%;
}


@media screen and (min-width: 2000px) {
    .app__supportus-calendar_img {
        width: 650px;
    }

    .app__supportus-calendar_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
    .app__supportus-calendar {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__supportus-calendar_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__supportus-calendar_img {
        width: 100%;
    }

    .app__supportus-calendar_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}