.app__about {
    flex-direction: column;
    background: var(--color-darkblack);
    color: var(--color-darkblack);
    margin-bottom: 5rem;
}

.app__about-title {
    margin-bottom: 0rem;
    padding-top: 5rem;
    background: var(--color-darkblack);
    text-align: center;
}


.app__about-about_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    background: var(--color-darkblack);
}

.app__about_about {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__about-about_img {
    width: 410px;
}

.app__about-about_img img {
    width: 100%;
    height: auto;

}

.app__about-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0rem;
}

.app__wrapper_info {
    margin-bottom: 5rem;
    padding: 2rem;
}

.app__wrapper_info .headtext__cormorant {
    margin-bottom: 1rem;    
    font-size: 40px;
}

.about_video_container{
    text-align: center;
    align-items: center;
    align-content: center;
}

.about_youtube {
    width: 500px;
    height: 450px;
}

.about_image_container {
    align-items: center;
    text-align: center;
    align-content: center;
    margin-bottom: 4rem;
}

.about_image_container img {
    width: 400px;
    height: 500px;
}

.threecord_container {
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.app__about-content_threecord{
    width: 600px;
    margin-top: 2rem;
    padding: 0rem 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

@media screen and (min-width: 2000px) {
    .app__shows-calendar_img {
        width: 650px;
    }

    .app__shows-calendar_img img {
        height: 920px;
    }

    .about_youtube {
        width: 1200px;
        height: 800px;
    }

    .about_image_container img {
        width: 400px;
        height: 500px;
    }
}

@media screen and (max-width: 1400px) {

    .about_youtube {
        width: 800px;
        height: 500px;
    }

    .threecord_container {
        padding: 2rem 10rem;
    }

}

@media screen and (max-width: 1150px) {
    .app__shows-calendar {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .about_youtube {
        width: 600px;
        height: 485px;
    }
    
}

@media screen and (max-width: 650px) {
    .app__shows-calendar_img {
        width: 100%;
    }

    .app__shows-calendar_heading {
        font-size: 35px;
        line-height: 48.5px;
    }

    .about_youtube {
        width: 350px;
        height: 300px;
    }

    .about_image_container img {
        width: 300px;
        height: 400px;
    }

    .app__about-content_threecord{
        width: 300px;
    }
}