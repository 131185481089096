.donate{
    background-color: rgb(214, 188, 40);
    color: black;
    padding: 1rem 1rem;
    align-items: center;
    border-radius: 15px;
    border:0px;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 0.04em;
    width: 400px;

}

.donate-button {
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
    background-color: transparent;
    transition: background-color 0.5s;
    text-align: center;
    position: relative;
}

.donate-button:hover .donate{
    color:white;
    background-color: rgb(138, 121, 24);
}

@media screen and (max-width: 650px) {

    .donate {
        width: 75%;
    }
}