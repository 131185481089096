.app__collaborators {
    flex-direction: column;
}

.app__wrapper_img {
    width: 100px;
    height: auto;
}

.app__collaborators-container {
    flex-direction: column;
    padding-bottom: 2rem;
    margin-bottom: 0rem;
}

.app__collaborators-title {
    text-align: center;
}

.app__wrapper_collaborators {
    height: auto;
    outline-color: var(--color-crimson);
    padding: 30px;
    margin: 20px;
    border-radius: 20px;
    outline-style: solid;
}

.app__collaborators-body {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0rem;
}

.donate_container {
    top: 50%;
    left: 50%;
}

.contributorimg {
    width: 500px;
    height: 500px;
}


@media screen and (max-width: 1000px) {
    .contributorimg {
        width: 400px;
        height: 400px;
    }

    .app__collaborators-calendar_img img {
        height: 300px;
    }
}

@media screen and (max-width: 1150px) {
    .app__collaborators-calendar {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__collaborators-calendar_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__collaborators-calendar_img {
        width: 100%;
    }

    .contributorimg {
        width: 250px;
        height: 250px;
    }

    .app__collaborators-calendar_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}