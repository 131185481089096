.app__tourhistory {
    flex-direction: column;
    margin-bottom: 0rem;
}

.app__tourhistory-container {
    flex-direction: column;
    padding-bottom: 10rem;
    padding-top: 0rem;
}

.app__tourhistory-title {
    text-align: center;
}

.app__wrapper_tourhistory {
    height: auto;
}

.app__tourhistory-body {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0rem;
}

.donate_container {
    top: 50%;
    left: 50%;
}

.app__wrapper_tourhistory{
    outline-style: solid;
    outline-color: var(--color-crimson);
    padding: 30px;
    margin: 20px;
    border-radius: 20px;
}

.tourhistory_listcontainer{
    margin: 0;
    padding: 0;
    text-align: left;
}

.tourhistory_listitem{
    font-size: 20px;
    color: var(--color-white);
    font-family: var(--font-base);
    margin-bottom: 1rem;
}


@media screen and (min-width: 2000px) {
    .app__tourhistory-calendar_img {
        width: 650px;
    }

    .app__tourhistory-calendar_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
    .app__tourhistory-calendar {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__tourhistory-calendar_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__tourhistory-calendar_img {
        width: 100%;
    }

    .app__tourhistory-calendar_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}