.order-tickets{
    background-color: rgb(214, 188, 40);
    color: black;
    padding: 1rem 1rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
    border-radius: 15px;
    border:0px;
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.04em;
    transition: background-color 0.5s;
}

#order-button {
    position: fixed;
    bottom: 144px;
    right: 10px;
}

#order-button:hover .order-tickets{
    color:white;
    background-color: rgb(138, 121, 24);
}

/*@media screen and (max-width: 1150px) {
    .order-tickets{
        display:none;
    }
} */