.app__profile-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 1rem;
}

@media screen and (min-width: 2000px) {
    .app__chef-sign img {
        width: 370px;
    }
}

@media screen and (max-width: 450px) {
    .app__chef-sign img {
        width: 80%;
    }
}