
.app__footer-links_icons_fixed {
    margin-top: 0.5rem;
    width: 50px;
    flex-wrap: wrap;
}

.app__footer-links_icons_fixed svg {
    color: var(--color-white);
    margin: 0.5rem;
    font-size: 24px;
    cursor: pointer;
}

.app__footer-links_icons_fixed svg:hover {
    color: var(--color-golden);
}

.icon-box {
    background-color: white;
    border-radius: 50%;
    padding: 3px;
    margin: 3px;
    height: 48px;
    width: 48px;
    transition: background-color 0.3s;
    flex-wrap: wrap;
}

.icon-box:hover {
    background-color: rgb(161, 161, 161);
}

#social-icons {
    position: fixed;
    left: 5px;
    font-size: 10px;
    width: 50px;
    height: 150px;
    bottom: 10rem;
}